html,
body {
  height: 100vh;
}

.content {
  padding-top: 8px;
}

.main {
  padding: 72px 124px;
  background-color: #e9e9e9;
}

@media only screen and (max-width: 426px) {
  .main {
    padding: 16px;
  }
}
