.brandWapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  svg{
    width: 72px;
    margin: 0 auto;
  }

}
.brand {
  display: flex;
  flex-direction: column;
  margin: 16px;
  
  .brandText {
    font-size: 24px;
    font-weight: 600;
    color: #035a94;
  }
  .brandSubText{
    color: #388dc5;
  }
}
ul {
  margin: 0;
  padding-left: 16px;
  color: #388dc5;
  li::marker{
    color: #035a94;
  }
}

.brandContainer {
  display: flex;
  max-width: 460px;
  height: 180px;
  padding: 16px 16px 0;
  .brandWrapper {
    display: flex;
    flex-direction: column;
    margin: 16px;
    align-self: center;
  
    .brandText {
      font-size: 16px;
      font-weight: 600;
      color: #035a94;
      margin-bottom: 8px;
    }
    .brandSubText {
      color: #388dc5;
    }
  }
}

@media only screen and (max-width: 733px) {
  .listBrand{
      display:none;
  }
}
