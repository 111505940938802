.footerContainer {
  display: flex;
  flex-direction: column;
}
.footer {
  background-color: #388dc5;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .brand,
  .contact,
  .nav,
  .iframe {
    max-width: 318px;
    padding: 16px;
    h2,
    p,
    span,
    button,
    a {
      color: #fff;
    }
    h2 {
      margin-bottom: 42px;
    }
  }
  .iframe {
    align-self: center;
  }
  .brandContainer {
    display: flex;
  }
  .brandWrapper {
    display: flex;
    flex-direction: column;
    margin: 16px;

    .brandText {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 8px;
    }
    .brandSubText {
      color: #fff;
    }
  }
  .categories {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    button {
      font-size: 14px;
    }
  }
  .contact {
    .infos {
      div {
        margin: 8px 0;
      }
      svg {
        margin-right: 8px;
      }
    }
  }
}
.policy {
  background-color: #388dc5;
  color: #fff;
  margin: 0;
  text-align: center;
  padding-bottom: 8px;
}

@media only screen and (max-width: 426px) {
  .footer {
    h2 {
      text-align: center;
    }
    .categories {
      align-items: center;
      justify-content: center;
    }
    .contact {
      .infos {
        div {
          margin: 8px 0 8px 32px;
        }
      }
    }
  }
}
