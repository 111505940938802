.card {
    border-radius: 6px;
    text-align: center;
    box-shadow: rgb(0 0 0 / 25%) 0 4px 12px 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 560px;
    img {
        margin: 0 auto;
        width: 100%;
        width: -webkit-fill-available;
        max-width: 460px;
        height: 180px;
        padding: 16px 16px 0 ;
    }
    h2 {
        text-align: left;
        color:#388dc5;
        padding: 0 16px 0 ;
    }
    .divider {
        border: 2px solid #035a94;
        margin: 0 16px 4px;

    }

    .text {
        text-align: left;
        padding: 0 16px  ;
    }
    .button {
        margin: auto 0 0 ;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

@media only screen and (max-width: 426px) {
    .card {
      width: 100%;
    }
  }
  