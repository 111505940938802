.title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #035a94;
  padding: 16px;
}
.logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 24px;
  gap: 16px;
}
.img {
  max-height: 62px;
  width: auto;
  height: auto;
  max-width: 172px;
}
