.svg {
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
  position: relative;
  fill: currentColor;
  width: auto;
  height: auto;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
